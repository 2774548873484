
import WinesContent from '~/bbrTheme/modules/catalog/product/components/product-types/content/WinesContent.vue';

export default {
  name: 'AccessoriesContent',
  mixins: [WinesContent],
  props: {
    displayAboutThisSection: {
      type: Boolean,
      default: false,
    },
    displayAttributesList: {
      type: Boolean,
      default: false,
    },
    displayTastingNote: {
      type: Boolean,
      default: false,
    },
  },
};
